@media only screen and (min-width: 800px){
    .AboutUs{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80vw;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 1rem;
        border-radius: 8px;
    }

    .ContactUs {
        width: 80vw;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 1rem;
        border-radius: 8px;
    }
}

/* Contact Component CSS */

.contact-form-field {
    margin-bottom: 20px !important;
}

.contact-form-submit {
    background-color: #B32800 !important;
    color: #FFFFFF !important;
}

.contact-form-submit:hover {
    background-color: #f99c38 !important;
}